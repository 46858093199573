import loadable from "@loadable/component";
import { inject, observer } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import type { SitesPodcastsBlock } from "@ihr-radioedit/inferno-webapi";
import { getPodcasts } from "../../services/Podcast";
import type { PageBlockInterface } from "../Block.component";

const Podcasts = loadable(() => import("./Podcasts.component"));

export interface PodcastsLoaderProps extends PageBlockInterface {
  store?: Store;
}

export function isPodcastBlock(block: any): block is SitesPodcastsBlock {
  return !!block.podcasts;
}

const PodcastsLoader = inject("store")(
  observer(({ block, isPrimary, store }: PodcastsLoaderProps) => {
    if (!block || !store) {
      return null;
    }

    if (!isPodcastBlock(block)) {
      return null;
    }

    const cacheKey = `$podcastloader-${block.podcasts.join(",")}`;
    return (
      <Remote
        cacheKey={cacheKey}
        loader={() => getPodcasts(block.podcasts, store?.tags.surrogateKeys)}
        showLoading={isPrimary}
      >
        {({ data: podcasts }) => (podcasts?.length ? <Podcasts block={block} podcasts={podcasts} /> : null)}
      </Remote>
    );
  }),
);
export default PodcastsLoader;
