import * as Webapi from "@ihr-radioedit/inferno-webapi";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getSdk, sdkOpts } from "@ihr-radioedit/inferno-core";
// import { ObservableMap } from "mobx";

const sdk = getSdk(sdkOpts());

const log = ILog.logger("Podcast Service");

export async function getPodcast(variables: Webapi.GetPodcastEpisodesQueryVariables, surrogateKeys: Set<string>) {
  surrogateKeys.add("ihr-amp");
  return sdk!.GetPodcastEpisodes.queryAsPromise(variables).then(response => response.data?.amp.podcast);
}

export async function getPodcasts(podcastIds: string[], surrogateKeys: Set<string>) {
  const podcasts = await Promise.all(
    podcastIds.map(async podcastId => {
      try {
        return await getPodcast({ id: parseInt(podcastId, 10), paging: { take: 10 } }, surrogateKeys);
      } catch (err) {
        log.debug(`Loading podcast with id ${podcastId} has an error:`, err.message);
        return null;
      }
    }),
  );

  return podcasts.filter((podcast): podcast is Webapi.PodcastFragment => Boolean(podcast));
}

export const getPodcastEpisodes = async (
  variables: Webapi.GetPodcastEpisodesQueryVariables,
  surrogateKeys: Set<string>,
  cacheKey = "",
) => {
  surrogateKeys.add(cacheKey ?? `podcast-episodes${variables.id}`);
  return sdk!.GetPodcastEpisodes.queryAsPromise(variables).then(
    response => response.data?.amp.podcast?.episodes.items ?? [],
  );
};
